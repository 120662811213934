import { createStore } from "vuex";

export default createStore({
  state: {
    isOpen: false,
    ePopup: false,
    isLoading: false,
  },
  mutations: {
    TOGGLE_MENU: (state) => (state.isOpen = !state.isOpen),
    TOGGLE_EMAIL: (state) => (state.ePopup = !state.ePopup),
    SET_LOADER: (state) => (state.isLoading = !state.isLoading),
  },
});
