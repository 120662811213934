<template>
  <div class="wrapper">
    <Carousel
      :navigation="true"
      :pagination="true"
      :startAutoPlay="false"
      :timeout="5000"
      :touchDrag="true"
      :mouseDrag="true"
      class="carousel"
      v-slot="{ currentSlide }"
    >
      <Slide v-for="(slide, index) in carouselSlides" :key="index">
        <div v-show="currentSlide === index + 1" class="slide-info">
          <img :src="require(`@/assets/works/${slide}.jpg`)" alt="bg-1.jpg" />
        </div>
      </Slide>
    </Carousel>
    <section class="content" id="tjanster">
      <ServicesContentful :query="{ content_type: 'services' }" />
    </section>
    <Contact />
    <About />
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import Slide from "@/components/Slide.vue";
import About from "./About.vue";
import Contact from "./Contact.vue";
import ServicesContentful from "../components/contentful/ServicesContentful.vue";

export default {
  name: "HomeComponent",
  components: { Carousel, Slide, About, Contact, ServicesContentful },
  setup() {
    const carouselSlides = [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-4",
      "bg-5",
      "bg-6",
      "bg-7",
    ];
    return { carouselSlides };
  },
};
</script>
<style lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: block;
  flex-direction: column;
  position: relative;
  margin: auto;
  span {
    display: flex;
    padding-bottom: 1em;
  }
  section {
    max-width: 940px;
  }

  .single-img {
    width: 100%;
    height: calc(100% - 200px);
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .content {
    color: #000;
    margin: 0 auto;
    padding: 2em 0.5em;
    max-width: 100%;
    background-color: #c2bbae;
    .text {
      margin: 0 auto;
      max-width: 940px;
      h2 {
        font-size: 32px;
        padding: 1em 0;
      }
      li {
        padding: 1em 0;
      }
    }
    &:nth-of-type(2n) {
      background-color: #5e2a31;
    }
  }
  @media (min-width: 924px) {
    .content {
      padding: 2em 0;
    }
  }

  .carousel {
    position: relative;
    margin: auto;
    display: block;
    height: 500px;
    @media (min-width: 968px) {
      min-height: 700px;
      // max-width: 1440px;
    }

    .slide-info {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 500px;
      background-color: #ffff;
      @media (min-width: 968px) {
        height: 700px;
      }
      img {
        width: 100%;
        display: block;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        @media (min-width: 968px) {
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
}
</style>
