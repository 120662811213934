<template>
  <section class="wrapper">
    <div v-for="item in items" :key="item.sys.id">
      <div class="inside-content" :item="item">
        <h2>
          {{ item.fields.title }}
        </h2>
        <h3>
          {{ item.fields.titletwo }}
        </h3>
        <ul>
          <li>
            <span>{{ item.fields.boldOne }}</span> {{ item.fields.textone }}
          </li>
          <li>
            <span>{{ item.fields.boldTwo }}</span> {{ item.fields.texttwo }}
          </li>
          <li>
            <span>{{ item.fields.boldThree }}</span> {{ item.fields.textthree }}
          </li>
        </ul>

        <h4>
          {{ item.fields.textbold }}
        </h4>

        <p>
          {{ item.fields.textfour }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { createClient } from "contentful";

const client = createClient({
  space: process.env.VUE_APP_MY_ENV_SPACE,
  accessToken: process.env.VUE_APP_MY_ENV_ACCESSTOKEN,
});

export default {
  props: {
    query: {
      type: Object,
      document,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      error: null,
    };
  },

  beforeMount() {
    client
      .getEntries(this.query)
      .then(({ items }) => {
        this.items = items;
      })
      .catch((error) => (this.error = error));
  },
};
</script>
<style scoped lang="scss">
.inside-content {
  padding: 2em 0.5em;
  h2 {
    padding: 0.5em 0;
  }
  h3 {
    padding: 1em 0 0;
  }
  h4 {
    padding: 0.5em 0;
  }
  p {
    padding: 0.5em 0;
  }
  ul {
    padding: 1em 0.2em;
    li {
      padding: 1em 0;
      span {
        font-weight: bold;
      }
    }
  }
}
@media (min-width: 924px) {
  .inside-content {
    padding: 2em 1em;
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 22px;
    }
    p,
    li,
    h4 {
      font-size: 20px;
    }
  }
}
</style>
