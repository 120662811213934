<template>
  <section class="wrapper">
    <div v-for="item in items" :key="item.sys.id">
      <div class="inside-content" :item="item">
        <h2>
          {{ item.fields.title }}
        </h2>
        <p>{{ item.fields.firstp }}</p>
        <p>{{ item.fields.secondp }}</p>
        <p>{{ item.fields.thirdp }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { createClient } from "contentful";
const client = createClient({
  space: process.env.VUE_APP_MY_ENV_SPACE,
  accessToken: process.env.VUE_APP_MY_ENV_ACCESSTOKEN,
});

export default {
  props: {
    query: {
      type: Object,
      document,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      error: null,
    };
  },

  beforeMount() {
    client
      .getEntries(this.query)
      .then(({ items }) => {
        this.items = items;
      })
      .catch((error) => (this.error = error));
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .inside-content {
    padding: 0;
    h2 {
      padding: 0.5em 0;
    }

    p {
      padding: 0.5em 0;
      font-size: 16px;
    }
  }
  @media (min-width: 940px) {
    .wrapper {
      p {
        font-size: 20px;
      }
    }
  }
}
</style>
