<template>
  <nav class="navbar">
    <div class="content">
    <Logo />
    <Sidebar :class="{ active: $store.state.isOpen }" />
    <div class="menu-desktop">
      <ul>
        <li><a href="#tjanster">Tjänster</a></li>
        <li><a href="#offert">Offert</a></li>
        <li><a href="#about">Om mig</a></li>
        <li><a href="#contact">Kontakt</a></li>
      </ul>
    </div>
    <HamburgerMenu />
    </div>
  </nav>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import Logo from "./Logo.vue";
export default {
  name: "NavbarComponent",
  components: { Sidebar, HamburgerMenu, Logo },
};
</script>

<style scoped lang="scss">
nav {
  background-color:#c2bbae ;
  color:black;
  .content{
    display: flex;
    margin: 0 auto;
    padding: 1em 2.8em;
    position: relative;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    color:#000;
    max-height: 100px;
    max-width: 940px;
  }
  svg {
    width: 90px;
    @media (min-width: 924px) {
      width: 200px;
      color: #343536;
      border-radius: 5px;
    }
  }

  img {
    padding:  1em;
    max-height: 5em;
    object-fit: center;
  }
  .menu-mobile {
    display: none;
    &.active {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(#333, 0.6);
    }
  }
  .menu-desktop {
    display: none;
  }
  @media (min-width: 940px) {
    .menu-desktop {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      color:#000;
      ul {
        display: flex;
        flex-direction: row;
        padding: 1em 1em 0 0;
        color:#000;
        li {
          color: #000;
          list-style: none;
          font-weight: normal;
          padding: 0 0.5em;
          cursor: pointer;
          font-size: 22px;
        }
        a {
          color: #000;
          text-decoration: none;
          position: relative;
          font-size: 1em;
          padding: 0.2em;
          &:hover {
            text-decoration: underline;
            transition: 5ms ease-in-out;
          }
        }
      }
    }
  }
} 
</style>
