<template>
  <div class="loader" :class="{ show: $store.state.isLoading }">
    <div class="outer">
      <div class="inner"></div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    return { store };
  },
};
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  position: fixed;
  background: rgba(#111, 0.95);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .outer {
    width: 150px;
    height: 150px;
    margin: auto;
    border: 5px solid;
    border-radius: 50%;
    display: flex;
    border-color: transparent #222 transparent #222;
    animation: spin 1s linear infinite;
  }

  .inner {
    @extend .outer;
    width: 100px;
    height: 100px;
    border-color: transparent #2f89fc transparent #2f89fc;
  }
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
