<template>
  <div class="menu-mobile" :class="{ toggle: $store.state.isOpen }">
    <ul>
      <li>
        <a href="#tjanster" @click="toggle">Tjänster</a>
      </li>
      <li>
        <a href="#offert" @click="toggle">Offert</a>
      </li>
      <li>
        <a href="#about" @click="toggle">Om mig</a>
      </li>
      <li><a href="#contact" @click="toggle">Kontakt</a></li>
    </ul>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
    name:"SidebarComponent",
  setup() {
    const store = useStore();
    const isOpen = computed(() => store.state.isOpen);
    const toggle = () => {
      store.commit("TOGGLE_MENU");
    };
    return { toggle, isOpen };
  },
};
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 34px;
  padding: 8em 1em 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ul {
    display: flex;
    flex-direction: column;
    li {
      padding: 0.2em 0;
      color: black;
    }
    a {
      color: white;
    }
  }
}
</style>
