<template>
  <div class="form-container">
    <div class="container">
      <form
        id="contact-info"
        spellcheck="false"
        @submit.prevent="handleSubmit"
        method="post"
      >
        <button type="button " class="close" @click="toggle"></button>
        <h3>Offert förfrågan</h3>
        <h4>Skicka mail så återkommer jag med offert</h4>
        <fieldset>
          <input
            placeholder="För och Efternamn"
            type="text"
            tabindex="1"
            required
            v-model="name"
          />
        </fieldset>
        <fieldset>
          <input
            placeholder="Email Address"
            type="email"
            tabindex="2"
            required
            v-model="email"
          />
        </fieldset>
        <fieldset>
          <input
            placeholder="Telefon"
            type="tel"
            v-model="tel"
            tabindex="3"
            required
          />
        </fieldset>
        <fieldset>
          <textarea
            placeholder="Skriv här"
            tabindex="4"
            v-model="message"
            required
          ></textarea>
        </fieldset>
        <span>
          <fieldset>
            <input
              name="checkbox"
              type="checkbox"
              id="checkbox"
              v-model="checked"
            />
            <p>Vi tar hänsyn till GDPR</p>
          </fieldset>
        </span>
        <fieldset>
          <button
            tabindex="5"
            name="submit"
            type="submit"
            :disabled="!checked"
            id="contact-submit"
            data-submit="...Sending"
          >
            Skicka
          </button>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { EmailServices } from "@/services/EmailServices";
import store from "@/store";
export default {
  name: "FormPopup",
  setup() {
    const namaste = useStore();
    const ePopup = computed(() => store.state.ePopup);
    const toggle = () => {
      store.commit("TOGGLE_EMAIL");
    };

    const initialState = {
      name: "",
      email: "",
      tel: "",
      message: "",
      checked: false,
    };
    const input = reactive({ ...initialState });

    const handleSubmit = () => {
      if (Object.values(input).some((i) => i === "")) {
        alert("Please fill in all field(s)");
        return;
      }

      store.commit("SET_LOADER");
      setTimeout(() => {
        EmailServices(input);
        Object.assign(input, initialState);
        store.commit("SET_LOADER");
        store.commit("TOGGLE_EMAIL");
      }, 2000);
    };

    return {
      ...toRefs(input),
      handleSubmit,
      ePopup,
      toggle,
      namaste,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  display: none;
  &.open {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(#333, 0.6);
    .container {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      color: #000;
    }
    span > fieldset {
      display: flex;
      align-items: center;
      padding: 1em 0 1em;
      flex-direction: row;
      p {
        margin-left: 1em;
      }
    }
    #contact-info input[type="text"],
    #contact-info input[type="email"],
    #contact-info input[type="tel"],
    #contact-info input[type="url"],
    #contact-info textarea,
    #contact-info input[type="checkbox"],
    #contact-info button[type="submit"] {
      font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
    }

    #contact-info {
      background: #f9f9f9;
      padding: 25px;
      margin: 150px 0;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }

    #contact-info h3 {
      display: block;
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    #contact-info h4 {
      margin: 5px 0 15px;
      display: block;
      font-size: 14px;
      font-weight: 400;
    }

    fieldset {
      border: medium none !important;
      margin: 0 0 10px;
      min-width: 100%;
      padding: 0;
      width: 100%;
    }

    #contact-info input[type="text"],
    #contact-info input[type="email"],
    #contact-info input[type="tel"],
    #contact-info input[type="url"],
    #contact-info textarea {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      margin: 0 0 5px;
      padding: 10px;
      font-size: 18px;
    }

    #contact-info input[type="text"]:hover,
    #contact-info input[type="email"]:hover,
    #contact-info input[type="tel"]:hover,
    #contact-info input[type="url"]:hover,
    #contact-info textarea:hover {
      -webkit-transition: border-color 0.3s ease-in-out;
      -moz-transition: border-color 0.3s ease-in-out;
      transition: border-color 0.3s ease-in-out;
      border: 1px solid #aaa;
    }

    #contact-info textarea {
      height: 100px;
      max-width: 100%;
      resize: none;
    }

    #contact-info button[type="submit"] {
      cursor: pointer;
      width: 100%;
      border: none;
      background: #343536;
      color: #fff;
      margin: 0 0 5px;
      padding: 10px;
      font-size: 15px;
    }

    #contact-info button[type="submit"]:hover {
      background: #343536;
      -webkit-transition: background 0.3s ease-in-out;
      -moz-transition: background 0.3s ease-in-out;
      transition: background-color 0.3s ease-in-out;
    }

    #contact-info button[type="submit"]:active {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    }

    #contact-info input:focus,
    #contact-info textarea:focus {
      outline: 0;
      border: 1px solid #aaa;
    }

    ::-webkit-input-placeholder {
      color: #888;
    }

    :-moz-placeholder {
      color: #888;
    }

    ::-moz-placeholder {
      color: #888;
    }

    :-ms-input-placeholder {
      color: #888;
    }
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  top: -2px;
  left: 13px;
  content: " ";
  height: 33px;
  width: 2px;
  opacity: 1;
  cursor: pointer;
  background-color: #000;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>
