<template>
  <section class="about" id="about">
    <AboutContentful :query="{ content_type: 'aboutme' }" />

    <div class="img-avatar">
      <img src="../assets/avatar.jpg" alt="avatar.jpg" />
      <h2>VD</h2>
      <span>David Pålman</span>
    </div>
  </section>
</template>

<script>
import AboutContentful from "./contentful/AboutContentful.vue";
export default {
    name:"AboutComponent",
  components: {
    AboutContentful,
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;

  flex-direction: column;
  height: 100%;
  padding: 2em 0.5em;
  justify-content: space-between;
  width: 100%;
  margin: auto;

  .about {
    padding: 1em;
  }
  .img-avatar {
    font-family: "PT Sans", sans-serif;
    padding: 0 0.5em;
    span {
      font-size: 16px;
    }
    img {
      display: block;
      max-width: 100%;
      border-radius: 5px;
    }
  }
}
@media (min-width: 924px) {
  section {
    display: flex;
    flex-direction: row;
    padding: 2em 0;
    .img-avatar {
      span {
        font-size: 24px;
      }
      img {
        padding: 0;
        max-width: 300px;
      }
    }
  }
}
</style>
