<template>
  <footer id="contact" class="footer">
    <div class="content">
      <div class="contact">
        <h1>Kontakt</h1>
        <ul>
          <li>
            Pålmans Måleri AB<br />
            c/o Win Win Ekonomi AB
          </li>
          <li>Box 92138-12008</li>
          <li>Stockholm</li>
          <li>Godkänd för F-skatt</li>
          <li>Org nr: 559241-0798</li>
          <ul>
            <li>
              <a href="mailto:palmansmaleri@gmail.com"
                >Mail: palmansmaleri@gmail.com</a
              >
            </li>

            <li>
              <a href="tel:0046723507030">Tel: 0723507030</a>
            </li>
          </ul>
        </ul>
      </div>
      <div class="contact">
        <h1>Följ Mig</h1>
        <div class="social">
          <a href="https://www.instagram.com/palmansmaleri/" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
          <!-- <i class="fab fa-instagram"></i> -->
          <a href="https://www.instagram.com/palmansmaleri/" target="_blank"
            >@Palmansmaleri</a
          >
        </div>
      </div>
      <div class="second-content">
        <div class="avatar">
          <Logo />
        </div>
        <div class="row">
          <p class="copy">
            © Copyright 2021 Pålmans Måleri AB. All rights reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "FooterComponent",
  components: { Logo },
};
</script>

<style lang="scss" scoped="true">
footer {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: #c2bbae;
  .content {
    width: 100%;
    max-width: 940px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 2em 1em;
    justify-content: space-between;
    .contact {
      padding: 2em 0;
    }
    h1 {
      padding-bottom: 1.5em;
      font-size: 24px;
    }
    i {
      font-size: 32px;
      padding-right: 0.5em;
    }
    a {
      font-size: 24px;
      color: #000;
      cursor: pointer;
      text-decoration: none;
    }

    ul {
      list-style: none;
      padding: 1em 0 0;
      font-size: 20px;
    }
    li {
      font-size: 24px;
      padding: 0.2em 0;
    }
    .second-content {
      .avatar {
        align-self: end;
        width: 200px;
      }
      p {
        padding-top: 2em;
      }
    }
  }
  .copy {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 1em 0.5;
    font-size: 12px;
  }
  .social {
      &:nth-child(2) {
        display: flex;
      }
  }  
  @media (min-width: 924px) {
    .footer {
      padding: 0 1em;
    }

    .social {
      &:nth-child(2) {
        padding-top: 1em;
      }
    }

    .content {
      width: 80%;
      padding: 1em 0 0;
      flex-direction: row;
      .contact {
        display: flex;
        flex-direction: column;
      }
      .second-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        svg {
          align-self: flex-end;
          padding: 2em 0.5em 2em 0;
        }
        p {
          margin-bottom: 6em;
        }
      }
      h1 {
        padding-bottom: 0.5em;
      }
      h2 {
        font-size: 24px;
      }
      .ul {
        padding: 0.5em 0;
        font-size: 20px;
        &.li {
          padding: 0;
        }
      }

      a {
        &:hover {
          text-decoration: underline;
          transition: 500ms ease-in-out;
        }
        .fa-instagram {
          &:hover {
            color: #d93531;
            transition: 500ms ease-in-out;
          }
        }
      }
    }
    .copy {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 0 1em;
      padding-bottom: 1em;
    }
  }
}
</style>
