<template>
  <section class="wrapper ">
    <div v-for="item in items" :key="item.sys.id">
      <div class="inside-content" :item="item">
        <h2>{{ item.fields.titleone }}</h2>
        <p>{{ item.fields.textone }}</p>
        <p>{{ item.fields.texttwo }}</p>
        <h2>{{ item.fields.titlerot }}</h2>
        <p>{{ item.fields.textrot }}</p>
        <div class="contact">
          <a href="mailto:palmansmaleri@gmail.com"
            >Mail: palmansmaleri@gmail.com</a
          >
          <a href="tel:0723507030">Tel: 0723507030</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createClient } from "contentful";
const client = createClient({
  space: process.env.VUE_APP_MY_ENV_SPACE,
  accessToken: process.env.VUE_APP_MY_ENV_ACCESSTOKEN,
});

export default {
  props: {
    query: {
      type: Object,
      document,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      error: null,
    };
  },

  beforeMount() {
    client
      .getEntries(this.query)
      .then(({ items }) => {
        this.items = items;
      })
      .catch((error) => (this.error = error));
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0;
  .inside-content {
    padding: 0 0.5em;
      color: #fff;
    h2 {
      padding: 0.5em 0;
    }

    p {
      padding: 0.5em 0;
      font-size: 16px;
    }
    .contact {
      display: flex;
      flex-direction: column;
      a {
        font-size: 20px;
        padding: 0.1em 0;
        text-decoration: none;
        color: #ebebeb;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (min-width: 940px) {
    .wrapper {
      // padding-right: 5em;
      p {
        font-size: 20px;
      }
    }
  }
}
</style>
