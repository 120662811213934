<template>
  <section class="content" id="offert">
    <ContactContentul :query="{ content_type: 'contact' }" />
    <div class="text">
      <h2 class="center">Kontakta mig för en kostnadsfri offert</h2>
      <span class="mail-text">
       <p>Vi tar hänsyn till GDPR och sparar ingen information eller delar den inte vidare!</p>
       <p></p>
      </span>
      <span class="mail-button">
      <a href="mailto:palmansmaleri@gmail.com" class="open-form">
       Kontakta mig här! 
      </a>

      </span>
    </div>
    <FormPopup :class="{ open: $store.state.ePopup }" />
  </section>
</template>

<script>
import ContactContentul from "./contentful/ContactContentful.vue";
import FormPopup from "@/components/FormPopup.vue";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  name: "ContactComponent",
  components: { FormPopup, ContactContentul },
  setup() {
    const store = useStore();
    const ePopup = computed(() => store.state.ePopup);
    const toggle = () => {
      store.commit("TOGGLE_EMAIL");
    };

    return { ePopup, toggle };
  },
};
</script>

<style lang="scss" scoped>
.content  {
  text-align: center;
  padding: 5em 0.5em;
  .center {
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 32px;
  }
}
.mail-text{
  display: flex;
  flex-direction: column;
  margin-left:.2em ;
  font-size: 20px;
  color:#fff;
}
.mail-button{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-bottom: 1em;
  margin-bottom:1rem;
  padding: 0 19rem
  
}
.open-form {
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 5px;
  background: #343536;
  color: #fff;
  margin: 0 0 5px;
  padding: 0.5em;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    transition: 0.2s ease-in-out;
  }
}
 @media (max-width: 924px){
  p {
    font-size: 16px;
  }
  .center{
    font-size: 24px!important;
  }
  .mail-button{
  padding: 0 0;
  }
 } 
</style>
