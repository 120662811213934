<template>
  <Navbar />
  <AppLoadingScreen />
  <Home />
  <Footer />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Home from "@/components/Home.vue";
import AppLoadingScreen from "@/components/AppLoadingScreen.vue";
export default {
  name: "App",
  components: { Home, Navbar, Footer, AppLoadingScreen },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
#app {
  width: 100%;
  h1,
  h2,
  h3,
  h4 {
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
  }
  p,
  li,
  a,
  button {
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
  }
  ul {
    list-style: none;
  }
  .a {
    text-decoration: none;
    &:hover {
      transition: 500ms ease-in-out;
    }
  }
}
</style>
